import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tout = makeShortcode("Tout");
const Box = makeShortcode("Box");
const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Event Formats`}</h1>
    <h2>{`Planning your next meetup`}</h2>
    <p>{`Defining the audience and purpose of the event makes it easier to decide how to structure the event.`}</p>
    <h3>{`Audience`}</h3>
    <p>{`When planning an event, it's important to consider who it's for and what purpose it will serve.`}</p>
    <p>{`Is the event for students who've never heard of crypto? Is it for developers looking to gain new skills? Maybe the goal is to help promote local businesses developing decentralized technologies.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`Educational`}</h3>
        <p>{`Teach and share ideas through debates, Q&A, workshops, professional panels, etc.`}</p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Technical`}</h3>
        <p>{`Educate developers and business professionals on the development of new technologies.`}</p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Social`}</h3>
        <p>{`Connect community members and organizations.`}</p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Finance and Investment`}</h3>
        <p>{`Educate investors on Dai, Maker, and the development of DeFi.`}</p>
      </Box>
    </Tout>
    <h2>{`Formats`}</h2>
    <p>{`This section provides ideas for the different types of events organizers can host.`}</p>
    <p>{`All events may be hosted virtually or IRL (in real life).`}</p>
    <p>{`Here are some suggestions for different formats community members can utilize for both virtual and in-person events.`}</p>
    <h3>{`Presentations`}</h3>
    <p>{`Good for introductions to various topics and ideas or deep dives focusing on usiness applications, integrations, and business strategy.`}</p>
    <p>{`E.g. How did we solve our dapp's issues with Dai? How can Ethereum affect commerce and the future of currency?`}</p>
    <h3>{`Debates, Panels, AMA Sessions, and Fireside Chats`}</h3>
    <p>{`Introduce audiences to new technologies or explore problems facing the industry.`}</p>
    <h3>{`Workshops and Demos`}</h3>
    <p>{`Increasing engagement with attendees can add substance to very presentations or simply make the event more enjoyable.`}</p>
    <p>{`Demonstrating how something works is a wonderful way to provide value for attendees`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Simple Demos`}</strong></p>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`How do I `}<a parentName="p" {...{
                "href": "https://awesome.makerdao.com/#earn-dai"
              }}>{`get`}</a>{` Dai?`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`How do I `}<a parentName="p" {...{
                "href": "https://awesome.makerdao.com/#hold-dai"
              }}>{`store`}</a>{` Dai?`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`How do I `}<a parentName="p" {...{
                "href": "https://awesome.makerdao.com/#spend-dai"
              }}>{`spend`}</a>{` Dai?`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`How can I `}<a parentName="p" {...{
                "href": "https://awesome.makerdao.com/#use-dai"
              }}>{`use`}</a>{` Dai?`}</p>
          </li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Complex Demos`}</strong></p>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Using a Vault`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Playing with `}<a parentName="p" {...{
                "href": "https://awesome.makerdao.com/#use-dai"
              }}>{`DeFi dapps`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Setting up and using `}<a parentName="p" {...{
                "href": "https://docs.makerdao.com/building-with-maker/daijs"
              }}>{`Dai.js`}</a></p>
          </li>
        </ul>
      </Box>
    </Tout>
    <h3>{`Social Events`}</h3>
    <p>{`Who said meetups can't be fun?`}</p>
    <p>{`Enabling attendees to purchase food and/or beverages with cryptocurrency can be a fun addition to an event. We don't fund bar tabs but here are some ideas for more casual events:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Dai Dappy Hour`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Casual Maker and DeFi Fan meetups`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Games`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Raffles`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Dai Auctions`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Donation Matching Initiatives`}</p>
      </li>
    </ul>
    <Callout icon="pencil" mdxType="Callout">
      <p>{`If you have insight you’d like to share, feel free to suggest new guides or edits to the existing ones. You can find out how on our `}<a parentName="p" {...{
          "href": "./content/"
        }}>{`content page`}</a></p>
    </Callout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      